.home-card-button {
  position: relative;
  padding: 3px 17px 3px 35px;
  margin-bottom: 1px;
}

.home-card-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 40px solid white;
  border-right: 24px solid transparent;
  width: 0;
}